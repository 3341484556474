import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import SystemsForVentilationComponent from '../components/systems-for-ventilation/systems-for-ventilation.component'


const SystemsForVentilation = ({data}) => {

    const { nodes } = data.allMarkdownRemark

    return (
        <Layout>
            <SystemsForVentilationComponent system={nodes}/>
        </Layout>
    )
}

export default SystemsForVentilation

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(systems)/"}}) {
      nodes {
        frontmatter {
          title
          description
          specifications {
            option
            value
          }
          image {
            relativePath
            publicURL
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
