import React from "react"
import {useTranslation} from "react-i18next"
import Seo from "../seo";
import HeaderComponent from "./header/header.component";
import SystemComponent from "./systems/system.component";

import './systems-for-ventilation.component.less';

const SystemsForVentilationComponent = (props) => {
    const {i18n, t} = useTranslation();

    return (
        <div className="system-ventilation">
            <Seo description={t("metaDescriptionMain")} lang={i18n.language}
                 title={t("Systems for ventilation, air purification and decontamination")} />
            <HeaderComponent />

            <section className="section-container">
                <div className="system-elements">
                    {/*SYSTEM*/
                        props.system.map((n, index) => {
                            const {title, specifications, description } = n.frontmatter
                            const image = n.frontmatter.image
                            
                            return (
                                <SystemComponent 
                                    title = {title}
                                    description = {description}
                                    specifications = {specifications}
                                    image = {image}
                                />
                            )
                        })
                    }
                </div>
            </section>
            
        </div>    
    )
}

SystemsForVentilationComponent.propTypes = {}

SystemsForVentilationComponent.defaultProps = {}

export default SystemsForVentilationComponent