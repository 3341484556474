import React from 'react';

import './header.component.less';

const HeaderComponent = () => {
    return (
        <div className='system-ventilation__intro'>
            <div className='section-container'>
                <h1 className='page-title system-ventilation__page-title'>Systems for ventilation, air purification and decontamination</h1>
            </div>
        </div>
    )
}

export default HeaderComponent