import React from 'react'; 

import './system.component.less';

const SystemComponent = (props/*title, description, image, specifications*/) => {

    const image = props.image

    return(
        <div className="system">
            <h3 className="system__title">{props.title}</h3>
            <div className="system__info">
                <div className="system__description">{props.description}</div>
                <div className="system__specification">
                    <div className="system__specification-title">Technical specifications</div>
                    <div className="system__specification-items">
                        {props.specifications.map(el => {
                            return (
                                <div className="system__specification-item">
                                    <div className="system__specification-option">{el.option}</div>
                                    <div className="system__specification-value">{el.value}</div>
                                </div>
                            )
                        })}   
                    </div>    
                </div>
            </div>
            <div className="system__image-wrap">
                <img src={`${image[0].publicURL}`} className="system__image" />   
            </div> 
        </div>
    )
}

export default SystemComponent